/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Subtitle, Button, Title, Text, YouTube, SeparateLine, SeparateLineWrap, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"THEHUB"}>
        <SiteHeader />

        <Column className="css-oozntd css-42e4bw --full" name={"1y8cxw7s02j"} style={{"paddingTop":78,"paddingBottom":0}} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/30161c1702644c5482984fb873c00595_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/30161c1702644c5482984fb873c00595_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/30161c1702644c5482984fb873c00595_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/30161c1702644c5482984fb873c00595_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/30161c1702644c5482984fb873c00595_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/30161c1702644c5482984fb873c00595_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/30161c1702644c5482984fb873c00595_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/30161c1702644c5482984fb873c00595_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--1 --full flex--center" style={{"marginTop":0,"paddingTop":0,"paddingRight":0,"paddingBottom":0}} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper style={{"paddingTop":0,"paddingBottom":0}}>
              
              <Image className="--center" alt={""} src={"https://cdn.swbpg.com/t/37641/bea4d730b77740e09aafd9c03f0af966_s=350x_.png"} sizes="100vw" style={{"maxWidth":479}} srcSet={"https://cdn.swbpg.com/t/37641/bea4d730b77740e09aafd9c03f0af966_s=350x_.png 350w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--center fs--30 swpf--uppercase" style={{"marginTop":0,"marginBottom":25}} content={"<span style=\"color: var(--white);\">A global network for you and your career</span><br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--sbtn1 btn-box--pbtn2 btn-box--shape3 btn-box--cColor2 btn-box--shadow5 btn-box--center fs--24 ls--001 lh--1" use={"page"} href={"/contact#contact"} style={{"marginTop":18,"backgroundColor":"var(--color-blend--85)"}} target={null} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">JOIN THEHUB</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"39h88qhpshd"} style={{"paddingTop":30,"paddingBottom":33}}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape3 --shadow4" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37641/3b6d50d5f0c7404aa015f296fbbe4644_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37641/3b6d50d5f0c7404aa015f296fbbe4644_s=350x_.png 350w, https://cdn.swbpg.com/t/37641/3b6d50d5f0c7404aa015f296fbbe4644_s=660x_.png 660w, https://cdn.swbpg.com/t/37641/3b6d50d5f0c7404aa015f296fbbe4644_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--26" content={"Your careers social network"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"The HUB is the network that works for you and your career. With the right resource in the right place, we create conditions and opportunities for you to achieve your dreams."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape3 --shadow4" alt={"Personal branding"} src={"https://cdn.swbpg.com/t/37641/58cbd791483a41b592dec080f957576f_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37641/58cbd791483a41b592dec080f957576f_s=350x_.png 350w, https://cdn.swbpg.com/t/37641/58cbd791483a41b592dec080f957576f_s=660x_.png 660w, https://cdn.swbpg.com/t/37641/58cbd791483a41b592dec080f957576f_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--26" content={"Personal Branding"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"By strategically working with your personal branding you pave the way for success. Just as THE HUB connects you with the right resources, a strong personal brand opens doors and propels you towards your goals."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape3 --shadow4" alt={"Brands"} src={"https://cdn.swbpg.com/t/37641/95987a931aca4c6aa5362bb4aa19dbfc_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/37641/95987a931aca4c6aa5362bb4aa19dbfc_s=350x_.png 350w, https://cdn.swbpg.com/t/37641/95987a931aca4c6aa5362bb4aa19dbfc_s=660x_.png 660w, https://cdn.swbpg.com/t/37641/95987a931aca4c6aa5362bb4aa19dbfc_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--26" content={"Partners &amp; Brands&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":502,"paddingRight":0}} content={"With our Brand Partners, you gain access to exclusive benefits and offers that elevate your journey to success. THE HUB connects you with essential resources, these partnerships provide unique opportunities that propel you towards achieving your goals."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"07g2gdqkh0wv"} style={{"paddingTop":69,"paddingBottom":35,"backgroundColor":"rgba(64, 64, 64, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left" style={{"maxWidth":571}} content={"<span style=\"color: var(--color-blend--95);\">Be part of a global network that works for you and your career </span>"}>
              </Title>

              <Text className="text-box text-box--left" content={"<span style=\"color: var(--color-blend--95);\">With a wide network across Europe and resources to address the challenges and choices one faces throughout their career, we create the right conditions. THEHUB is there to support you before, during and after your career.</span>"}>
              </Text>

              <Button className="btn-box btn-box--cbtn2 btn-box--pbtn2 btn-box--shape3 btn-box--cColor2 btn-box--left fs--16" style={{"maxWidth":213,"marginTop":18,"backgroundColor":"var(--color-blend--95)"}} content={"<span style=\"font-weight: bold; color: var(--color-supplementary);\">THEHUB CHANNEL</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube fs={false} rel={"1"} loop={true} embedId={"v2UlLw-GNsM"} autoplay={true} controls={false} ivLoadPolicy={false} modestbranding={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"[[UNIsecntioname12]]-2"} style={{"paddingTop":19,"paddingBottom":34,"backgroundColor":"rgba(64, 64, 64, 1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s3 --center el--2 flex--top" anim={"5"} animS={"3"} style={{"marginTop":70,"paddingTop":0,"paddingBottom":10}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":"","marginBottom":25}}>
              
              <Image className="--shape4 --shadow4 --center" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37641/e4b823da6a6b4b189c9017b905ddf0b6_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"paddingLeft":0}} srcSet={"https://cdn.swbpg.com/t/37641/e4b823da6a6b4b189c9017b905ddf0b6_s=350x_.png 350w, https://cdn.swbpg.com/t/37641/e4b823da6a6b4b189c9017b905ddf0b6_s=660x_.png 660w, https://cdn.swbpg.com/t/37641/e4b823da6a6b4b189c9017b905ddf0b6_s=860x_.png 860w, https://cdn.swbpg.com/t/37641/e4b823da6a6b4b189c9017b905ddf0b6_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":480,"paddingLeft":0,"paddingBottom":74}}>
              
              <Title className="title-box title-box--left fs--48" style={{"maxWidth":556,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"color: var(--color-dominant);\">Be a part of the best brands in the world &nbsp;<br></span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":"","marginTop":27}} content={"<span style=\"color: var(--white);\">The best deserve the best. Part of THE HUB consists of companies and brands that all want the best for you and your career. We are constantly working to bring in more companies and benefits to you players in THE HUB.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"mz0o67fsi3"} style={{"backgroundColor":"rgba(48,45,78,1)"}}>
          
          <SeparateLineWrap className="--center" style={{"marginTop":0}} fullscreen={false}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-ahyim6 pb--60 pt--60" name={"contact"} layout={"l1"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pt--20" style={{"paddingBottom":65}}>
              
              <Title className="title-box fs--48 lh--1 mt--08" style={{"maxWidth":978,"paddingLeft":0}} content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">Ready to chase your dreams?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr5 btn-box--cbtn1 btn-box--sbtn2 btn-box--shadow3 btn-box--filling4 fs--18 lh--14" style={{"paddingLeft":0,"backgroundColor":"var(--white)"}} content={"<span style=\"color: rgb(31, 28, 58); font-weight: 700;\">ASK THE HUB</span><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"e9rh5l40q4r"} style={{"paddingTop":54,"paddingBottom":0}} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":0}}>
              
              <Title className="title-box fs--43" content={"<span style=\"color: var(--color-custom-1);\">BE A PART OF THEHUB<br></span>"}>
              </Title>

              <Title className="title-box fs--30" content={"<span style=\"color: var(--color-custom-1);\">JOIN TODAY</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--left fs--16" content={"<span style=\"color: var(--color-custom-1);\">info@thehub.info<br>www.thehub.info<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--16" content={"<span style=\"color: var(--color-custom-1);\">THEHUB<br>A network for you &amp; your career<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Subtitle className="subtitle-box fs--16" content={"<span style=\"color: var(--color-custom-1);\">Don't wait for the opportunitys. <br>Together</span>&nbsp;<span style=\"color: var(--color-custom-1);\">&nbsp;we create it</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 --shadow4 fs--16" style={{"marginBottom":66}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Telephone number","type":"text","placeholder":"Telephone No."},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"footern"} style={{"marginTop":148,"paddingTop":28,"paddingBottom":34,"backgroundColor":"rgba(32, 32, 32, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--top" style={{"backgroundColor":"rgba(32,32,32,1)"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--18" content={"<span style=\"color: var(--color-custom-1--95);\">THE HUB</span>"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":806}} content={"<span style=\"color: var(--color-custom-1--95);\">Tillgängligheten 1<br>417 10 Gothenburg<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":627,"paddingBottom":0}}>
              
              <Title className="title-box title-box--center fs--18" content={"<span style=\"color: rgb(246, 246, 248);\">ADRESS</span><br>"}>
              </Title>

              <Text className="text-box text-box--center fs--12" style={{"maxWidth":269}} content={"<span style=\"color: var(--color-custom-2--95);\">THEHUB&nbsp; AB<br>559470-2820</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape3 btn-box--center fs--12" use={"page"} href={"/contact#contact"} style={{"maxWidth":802,"marginTop":8,"paddingTop":9}} target={null} content={"<span style=\"font-weight: bold;\">Contact&nbsp;us</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box fs--18" content={"<span style=\"color: var(--color-custom-1--95);\">MENU</span>"}>
              </Title>

              <Text className="text-box fs--12 w--500 ls--04 lh--14" content={"<a href=\"#\" style=\"\"><span style=\"color: var(--color-custom-1--95);\">Home</span><br></a><a href=\"#\" style=\"color: var(--color-custom-2--95);\">Brands<br>THE HUB<br></a><a href=\"#\" style=\"color: var(--color-custom-2--95);\">Contact</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" style={{"backgroundColor":"rgba(32,32,32,1)"}} columns={"3"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}